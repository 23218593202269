import {
  useFormAction,
  useLocation,
  useNavigation,
  useParams,
} from '@remix-run/react'
import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

function matched<T>(x: T) {
  return {
    on: () => matched(x),
    otherwise: () => x,
  }
}

type Pred<T> = (x: T) => boolean
type Result<T> = (x: T) => any
export function match<T>(x: T) {
  return {
    on: (pred: Pred<T>, fn: Result<T>) =>
      pred(x) ? matched<T>(fn(x)) : match(x),
    otherwise: (fn: Result<T>) => fn(x),
  }
}

export function getSocialMetas({
  url,
  title = 'Compare Airport Lounge Access - LoungePair',
  // eslint-disable-next-line quotes
  description = "You could save up to 30% off walk-up rates by using LoungePair to compare access for over 250+ airport lounges worldwide. It's time to relax before you fly.",
  image = 'http://static1.squarespace.com/static/5a9b95c6266c0791181aaa8e/t/5d42c481a0a54800019f63e1/1564656771240/pexels-photo-322819.jpeg?format=1500w',
  keywords = '',
}: {
  image?: string
  url: string
  title?: string
  description?: string
  keywords?: string
}) {
  return [
    { title },
    { name: 'description', content: description },
    { name: 'keywords', content: keywords },
    { name: 'image', content: image },
    { property: 'og:url', content: url },
    { property: 'og:title', content: title },
    { property: 'og:description', content: description },
    { property: 'og:image', content: image },
    { property: 'og:type', content: 'website' },
    {
      name: 'twitter:card',
      content: image ? 'summary_large_image' : 'summary',
    },
    { name: 'twitter:creator', content: '@loungepair' },
    { name: 'twitter:site', content: '@loungepair' },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { name: 'twitter:image', content: image },
    { name: 'twitter:alt', content: title },
  ]
}

/**
 * Use the current route's form action.
 * Checks if the current route's form is being submitted.
 *
 * @default formMethod is POST.
 * @default state is non-idle.
 */
export function useIsPending({
  formAction,
  formMethod = 'POST',
  state = 'non-idle',
}: {
  formAction?: string
  formMethod?: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE'
  state?: 'submitting' | 'loading' | 'non-idle'
} = {}) {
  const contextualFormAction = useFormAction()
  const navigation = useNavigation()
  const isPendingState =
    state === 'non-idle'
      ? navigation.state !== 'idle'
      : navigation.state === state
  return (
    isPendingState &&
    navigation.formAction === (formAction ?? contextualFormAction) &&
    navigation.formMethod === formMethod
  )
}

export function getSupportUrl(): string {
  const params = useParams()
  const url = useLocation()

  let subject = ''
  if ('iata' in params) {
    subject = `Question about airport: ${params.iata}`
  }
  if ('slug' in params) {
    subject = `Question about lounge: ${params.slug}`
  }
  if ('id' in params) {
    subject = `Question about my order: ${params.id}`
  }
  if (url.pathname.includes('account')) {
    subject = 'Question about my account'
  }
  return `https://helpdesk.loungepair.com/help/3983236506?f[subject]=${encodeURIComponent(subject)}`
}
